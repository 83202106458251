import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, GuestGuard, MaintenanceGuard, NotMaintenanceGuard, NotStagingGuard, PendingGuard, PendingEmailGuard, StagingGuard, AddressPendingGuard } from '@fengbrasil/ngx-fengst-auth';
import { DesignSystemComponent, MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, PrivacyComponent, RecoveryPasswordComponent, StagingComponent, ScailingMessageComponent } from '@fengbrasil/ngx-fengst-layout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, GuestGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/fiche-se',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.StatutoryFormModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  // {
  //   path: 'minha-conta/adote-um-socio',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-account').then((m) => m.AccountDonationModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  // },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/biometria',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.BiometryModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/dependentes',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/meus-propostos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProposedModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
   {
    path: 'produtos-digitais',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.WelcomeKitModule),
    canActivate: [MaintenanceGuard, PendingGuard],
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'checkout/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  {
    path: 'checkout/deps/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterDepModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  {
    path: 'checkout/deps-animal/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterDepAnimalModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  {
    path: 'checkout/success',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  //Adote um Sócio - Carrinho
  {
    path: 'checkout/donation/subscribe',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDonationModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/cart-donation/:type',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartDonationModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  //Gift Cart - Carrinho
  {
    path: 'checkout/cart-gift/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartGiftModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'checkout/gift/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeGiftModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  //Dep
  //Sócio Estatutário - Carrinho
  // {
  //   path: 'checkout/cart-se/subscribe/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartSEModule),
  //   canActivate: [StagingGuard, MaintenanceGuard]
  // },
  // {
  //   path: 'checkout/se/registration',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationSeModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  // },
  {
    path: 'checkout/se/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeSeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  //Especial - Carrinho
  {
    path: 'checkout/cart-special/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartSpecialModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'checkout/special/registration',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/special/address',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/special/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeSpecialModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  //Dependente - Carrinho
  {
    path: 'checkout/cart-deps/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartDepsModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'checkout/deps/registration',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationDepModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/deps/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDepsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  //Dependente - Carrinho (Animal)
  {
    path: 'dependente-pet-foto',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResgistrationDepPetThubnailModule),
  },
  // {
  //   path: 'dependente-pet/register',
  //   loadChildren: () => import('./pages/dependent-pet/views/dependent-pet-nologged/dependent-pet-nologged.module').then(m => m.DependentPetModule),
  // },
  // {
  //   path: 'dependente-pet',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationDepPetModule),
  // },
  // {
  //   path: 'checkout/cart-deps-animal/:type/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartDepsAnimalModule),
  //   canActivate: [StagingGuard, MaintenanceGuard]
  // },
  // { 
  //   path: 'checkout/deps-animal/subscribe/:idplano', 
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDepsPetModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard]
  // },
  { 
    path: 'minha-conta/pets', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.DependentPetModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, AddressPendingGuard]
  },
  // {
  //   path: 'checkout/cart-deps-animal/:type/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartDepsAnimalModule),
  //   canActivate: [StagingGuard, MaintenanceGuard]
  // },
  // {
  //   path: 'checkout/deps-animal/registration',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationDepAnimalModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  // },
  // {
  //   path: 'checkout/deps-animal/subscribe/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDepsModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  // },
  {
    path: 'checkout/resubscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/upgrade/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/address',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/cart/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/registration-foreigner/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationForeignerModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard]
  },
  {
    path: 'checkout-se/cart/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartSEModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'checkout/cart',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'planos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
   //Gift Card
   {
    path: 'presente',
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      // meta: {
      //   robots: 'index, follow',
      //   title: 'Adote um Sócio, Gigante!',
      //   override: false,
      //   description:
      //     'É hora de mostrar porque o sentimento não para. O motivo de termos o maior plano de sócios do Brasil.',
      // },
    },
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-gift').then(
        (m) => m.GiftCardModule,
      ),
  },
   {
    path: 'presente/cadastro',
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      // meta: {
      //   robots: 'index, follow',
      //   title: 'Adote um Sócio, Gigante!',
      //   override: false,
      //   description:
      //     'É hora de mostrar porque o sentimento não para. O motivo de termos o maior plano de sócios do Brasil.',
      // },
    },
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-gift').then(
        (m) => m.GiftCardRegisterModule,
      ),
  },
  //Adote um Sócio
  //  {
  //   path: 'adote-um-socio',
  //   canActivate: [StagingGuard, MaintenanceGuard],
  //   data: {
  //     meta: {
  //       robots: 'index, follow',
  //       title: 'Adote um Sócio, Gigante!',
  //       override: false,
  //       description:
  //         'É hora de mostrar porque o sentimento não para. O motivo de termos o maior plano de sócios do Brasil.',
  //     },
  //   },
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-donation').then(
  //       (m) => m.DonationModule,
  //     ),
  // },
  //  {
  //   path: 'adote-um-socio/validation/:type',
  //   canActivate: [StagingGuard, MaintenanceGuard],
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-donation').then(
  //       (m) => m.DonationBearerModule,
  //     ),
  // },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'ingressos/meu-bilhete',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsSingleEmbeddedModule),
      canActivate: [MaintenanceGuard],
  },
  // {
  //   path: 'conteudo-exclusivo',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-exclusive-content').then((m) => m.MainModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  // },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'consulta-de-socio',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.AssociateModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'transparencia',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-xray').then((m) => m.XrayModule),
      canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'termos-e-politicas',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'termos-de-uso',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'politica-de-privacidade',
    component: PrivacyComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'WE',
    component: MaintenanceComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl: 'https://admin.stg.spfc.feng.rocks/WE',
    },
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  // {
  //   path: 'dependente-pet-foto',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResgistrationDepPetThubnailModule),
  // },
  // // {
  // //   path: 'dependente-pet/register',
  // //   loadChildren: () => import('./pages/dependent-pet/views/dependent-pet-nologged/dependent-pet-nologged.module').then(m => m.DependentPetModule),
  // // },
  // {
  //   path: 'dependente-pet',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationDepPetModule),
  // },
  // {
  //   path: 'checkout/cart-deps-animal/:type/:idplano',
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartDepsAnimalModule),
  //   canActivate: [StagingGuard, MaintenanceGuard]
  // },
  // { 
  //   path: 'checkout/deps-animal/subscribe/:idplano', 
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDepsPetModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard]
  // },
  // { 
  //   path: 'minha-conta/pets', 
  //   loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.DependentPetModule),
  //   canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, AddressPendingGuard]
  // },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


/** 
 * ng g m pages/subscribe-donation --project="@fengbrasil/ngx-fengst-checkout" --routing     
 * ng g c pages/subscribe-donation --project="@fengbrasil/ngx-fengst-checkout" --module="subscribe-donation" -is
 * */
