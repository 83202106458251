export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.dev.novogigante.feng.rocks/',
  // apiURL: 'http://localhost:3333/',
  weURL: 'https://admin.stg.sociogigante.com/',
  recaptcha: {
    key: '6LdiqAkfAAAAAISRQLSkAjRInAAHr1JEW64FIP61'
  },
  auth: {
    new: true,
    social: [
      { name: 'google', provider: '385980807876-eefakt2o60ti35lurhvu2ubhc2f0l3vu.apps.googleusercontent.com' },
      { name: 'facebook', provider: '1027045667898738' }
    ],
    cognito: {
      enable: false,
      REGION: 'us-east-1',
      USER_POOL_ID: '',
      APP_CLIENT_ID: '',
      API_APP_KEY: ''
    }
  }
};
