export const fengTheme = {
    name: 'feng',
    properties: {
        '--background': '#F8F8F8',
        '--on-background': '#000000',
        '--primary': '#141414',
        '--on-primary': '#F8F8F8',
        '--secondary': '#CFFF03',
        '--on-secondary': '#414141',
        '--surface': '#F8F8F8',
        '--on-surface': '#11224F',
        '--error': '#F25455',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--on-warning': '#fff',
        '--success': '#a5cc02',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--on-info': '#fff',
        '--primary-RGB': '65, 65, 65',
        '--primary-light': '#F8F8F8',
        '--primary-dark': '#0A0A0A',
        '--secondary-dark': '#748F02',
        '--secondary-RGB': '191,146,64',
        '--surface-RGB': '0, 0, 0',
        '--error-RGB': '255, 255, 255',
        '--warning-RGB': '255, 255, 255',
        '--success-RGB': '104,219,108',
        '--info-RGB': '255, 255, 255',
        '--on-background-RGB': '0,0,0',
        
        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Titillium Web',
        '--font-title': 'Titillium Web',
        '--font-button': 'Titillium Web',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'var(--primary)',
        '--menu-active-background': 'var(--secondary)',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/cruz-malta-transp.png) no-repeat top center / auto, url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/header-det1.png) no-repeat top left / auto, url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/header-det2.png) no-repeat top right / auto',
        '--header-bg-color': 'rgba(51, 51, 51, 0.04)',
        '--header-border-color': '#FFF',
        '--header-font-color': '#FFF',
        '--header-mobile-bg': 'black url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/header-nova-ajustada.png) no-repeat top left 42% / cover',
        '--header-mobile-logo-width': '145px',
        '--header-desktop-logo-width': '240px',
        '--header-mobile-height': '100px',
        '--header-desktop-height': '180px',
        '--header-desktop-content-padding': '0',
        '--header-mobile-content-padding': '0',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-bg': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/cruz-malta-transp.png) my-profile-name-color, url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/header-det1.png) no-repeat top left / auto, url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/header-det2.png) no-repeat top right / auto',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '260px',
        '--header-new-mobile-logo-width': '93.204px',
        '--header-new-desktop-logo-width': '181.279px',
        '--header-new-mobile-second-logo-width': '128.811px',
        
        '--header-counter-background': 'var(--primary)',
        '--header-counter-font-family': 'Heebo',
        '--header-counter-text-color': 'var(--secondary)',
        '--header-counter-color': 'var(--secondary)',
        '--header-counter-number-bg': 'var(--primary)',
        '--header-counter-number-border-color': 'var(--info)',
        '--header-counter-number-outline-color': 'var(--secondary-dark)',
        '--header-counter-number-font-family': 'ApexMk3',

        // Home Public
        // Call to Action
        '--home-call-to-action-font-family': 'Titillium Web',
        '--home-call-to-action-background': 'linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%)',
        '--home-call-to-action-background-img': 'linear-gradient(0deg, rgba(0,0,0,0.35) 100%, transparent), url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/cta-banner.png)',
        '--home-call-to-action-height': '800px',
        '--home-call-to-action-detail-color': 'var(--secondary)',
        //'--home-call-to-action-title-font-size': '72px',
        '--home-call-to-action-title-font-size': '66px',
        '--home-call-to-action-title-font-family': 'Titillium Web',
        '--home-call-to-action-title-font-weight': '900',
        '--home-call-to-action-title-margin': '0 0 24px 0',
        '--home-call-to-action-title-line-height': '130%',
        '--home-call-to-action-title-alternate-font-family': 'Roboto',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '20px',
        '--home-call-to-action-subtitle-width': '487px',
        '--home-call-to-action-subtitle-line-height': '130%',
        '--home-call-to-action-content-padding': '2% 20px',
        '--home-call-to-action-content-align': 'left',
        '--home-call-to-action-button-margin': '0',
        '--home-call-to-action-button-background': 'var(--secondary)',
        '--home-call-to-action-button-padding': '8px 30px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-button-color': 'var(--primary)',
        '--home-call-to-action-hover-button-color': 'white',
        '--home-call-to-action-title-color': 'white',
        //'--home-call-to-action-title-desktop-max-width': '716px',
        '--home-call-to-action-title-desktop-max-width': '928px',
        '--mobile-home-call-to-action-title-margin': '0 0 30px',
        '--mobile-home-call-to-action-content-padding': '80px 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--mobile-home-call-to-action-subtitle-font-size': '16px',
        '--mobile-home-call-to-action-subtitle-line-height': '150%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'ApexMk3',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',
        
        //Home tickets
        '--st-tickets-bg': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/partidas-bg.png) no-repeat center',
        '--st-tickets-desktop-padding': '32px 16px',
        '--st-tickets-mobile-padding': '16px',
        '--st-tickets-desktop-gap': '32px',
        '--st-tickets-mobile-gap': '24px',
        '--st-tickets-wrapper-bg': '#fff',
        '--st-tickets-desktop-wrapper-padding': '32px 40px',
        '--st-tickets-mobile-wrapper-padding': '24px',
        '--st-tickets-wrapper-text-color': '#393939',
        '--st-tickets-max-width': '1600px',
        '--st-tickets-action-max-width': '600px',
        '--st-tickets-action-desktop-gap': '16px',
        '--st-tickets-action-mobile-gap': '8px',
        '--st-tickets-box-shadow': '0 0 20px 5px rgba(0, 0, 0, 0.50)',
        '--st-tickets-outline-btn-color': '#333333',
        '--st-tickets-outline-btn-bg-color': '#fff',
        '--st-tickets-divider-color': '#333333',
        '--st-tickets-desktop-h2-font-size': '43px',
        '--st-tickets-desktop-h2-font-weight': '800',
        '--st-tickets-desktop-h2-line-height': '110%',
        '--st-tickets-mobile-h2-font-size': '28px',
        '--st-tickets-desktop-p-font-size': '32px',
        '--st-tickets-desktop-p-font-weight': '700',
        '--st-tickets-desktop-p-line-height': 'normal',
        '--st-tickets-mobile-p-font-size': '20px',
        '--st-tickets-shield-max-height': '98px',
        '--st-tickets-shield-img-width': '109px',
        '--st-tickets-shield-img-height': '109px',
        '--st-tickets-shield-img-width2': '95px',
        '--st-tickets-shield-img-height2': '95px',
        '--st-tickets-info-min-width': '245px',

        // Tabs
        '--home-desktop-tabs-1-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/img-conteudo1.png) no-repeat center',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/img-conteudo2.png) no-repeat center',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',
        '--home-view-tabs-wrapper-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-scroll-conteudo.png)',
        '--home-view-tabs-content-color': '#333333',
        '--home-view-tabs-box-shadow': '0 0 24px 5px rgba(0, 0, 0, 0.16)',
        '--home-view-tabs-p-color': '#727272',
        '--home-view-tabs-wrapper-bg-color': '#fff',
        '--home-view-tabs-icon-color': '#A5CC02',

        // First Access
        '--first-access-bg': '#000000',

        // Pending E-mail
        '--pending-mail-bg': 'white',
        '--pending-mail-color': 'black',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': '#000',

        // Home Private
        '--home-private-padding-top': '0',

        // Plans
        '--plans-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-planos.png) center no-repeat',
        '--plans-content-color': '#333333',
        '--plans-info-color': '#393939',
        '--plans-box-shadow': '-1.71058px 8px 16px 2px rgba(0, 0, 0, 0.24)',
        '--plans-border-color': '#a6cc02',

        // Event Info
        '--event-info-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-scroll-conteudo3.png) left no-repeat',

        // Partner Scroll
        '--partners-img-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-planos.png)',

        // My Profile
        '--my-profile-title-color': '#5B5B58',
        '--my-profile-name-color': '#5B5B58',

        // Proposts
        '--proposed-approve-button': 'var(--secondary)',
        '--proposed-approve-button-hover': '#99bd00',
        '--proposed-disapprove-button': 'var(--error)',
        '--proposed-disapprove-button-hover': '#cc4748',

        // Contact
        '--contact-background': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/flag-vasco1.png) no-repeat center center / cover',

        // Experiences
        '--experience-how-to-score-icon-filter': '',
        '--experience-points-table-background': '#ffffff',

        // Exclusive Content
        '--exclusive-content-text': 'var(--primary)',
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',

        // X-Ray 
        '--x-ray-text-color': '#232323',
        '--x-ray-call-to-action-background': '#000',
        '--x-ray-call-to-action-background-img': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/xray-featured-background.jpg)',
        '--x-ray-action-active-background-color': '#333333',
        '--x-ray-tabgroup-background': 'white',
        '--x-ray-action-not-active-background-color': '#D0D0D0',
        '--x-ray-tab-header-top-position': '50px',
        '--x-ray-tab-header-ink-bar-height': '0',
        '--x-ray-chart-colors': [
            'rgba(207, 255, 3)',
            'rgba(0, 0, 0)',
            'rgba(43, 45, 48)',
            'rgb(65,65,65)',
            'rgb(240, 241, 242)'
        ],
        '--x-ray-map-fill-color': '#000',
        '--x-ray-map-fill-hover-color': '#333333',
        '--x-ray-map-stroke-hover-color': '#CFFF03',
        '--x-ray-map-tooltip-background': 'rgba(0, 0, 0, 1.0)',
        '--x-ray-map-tooltip-border': '2px solid rgba(0, 0, 0, 0.7)',
        '--x-ray-map-tooltip-color': '#fff',
        '--x-ray-transparency-background-color': 'rgba(0, 0, 0, 1.0)',
        '--x-ray-transparency-on-background-color': '#fff',

        // Events
        '--event-background-select': 'rgba(255, 255, 255, 0.5)',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#CFFF03',
        '--event-color-text-button': '#000000',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': '#CFFF03',
        '--event-color-text-dark': '#313131',
        '--event-color-text-primary': '#545452',
        '--event-color-text-secondary': '#CFFF03',
        '--event-banner-text-primary': '#FFFFFF',
        '--event-banner-text-secondary': '#898989',

        // Biometry
        '--biometry-color-texts': '#393939',
        '--biometry-card-divider': '1px solid #E4E4E4',
        
        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': 'white',
        '--footer-background': '#141414',
        '--footer-logo-width': '240px',
        '--footer-font-family': 'Barlow Semi Condensed',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#000000',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',

        // Staging
        '--staging-logo-background-color': '#000000',
    },
    images: {
        'stLogo': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/logo-gigante+1.png',
        'stLogoMobile': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/logo-gigante+1.png',
        'stLogoFooter': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/logo-gigante+1.png',
        'loadingLogo': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/logo-gigante+1.png',
        'stShield': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/escudo-vasco.svg',
        '--homePlansBG': 'linear-gradient(180deg, #F8F8F8 0%, #000000 100%)',
        '--homeTicketsBG': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/bg-desktop.png)',
        '--homeExclusiveContentBG': 'url(https://vasco-gs.s3.amazonaws.com/prod/assets/images/bg-desktop.png)',
        'mailPendingLogo': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/logo-gigante+1.png',
        header: {
            'stShieldHeader': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/escudo-vasco.svg',
            'stSecondHeaderImg': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/vasco-faixa.svg',
            'counterMobileBG': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-contador-mobile.png',
            'counterDesktopBG': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/bg-contador-web.png',
        }
    },
    videos: {
        'bannerVideo': 'https://vasco-gs.s3.amazonaws.com/prod/assets/images/website/2023/video-destaque-1scroll_vasco.mp4'
    },
    texts: {
        // Home
        // Call to Action
        //'callToActionFeatured': 'QUE HONRA<br> SER SÓCIO GIGANTE!',
        'callToActionFeatured':'A BARREIRA É PRA TODOS<br> E TODOS SOMOS GIGANTES!',
        'callToActionFeaturedImg': '',
        'callToActionDetail': 'Tenha prioridade e desconto na compra de ingressos, diversos benefícios e experiências exclusivas.',
        'callToActionButton': 'SEJA GIGANTE',
        //Home tickets
        'stTicketsTitle': 'Garanta já o seu ingresso!',
        'stTicketsButton': 'SEJA GIGANTE',
        'stTicketsBenefits': 'VER BENEFÍCIOS',
        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve ler e concordar com os termos de uso e com a política de privacidade!',
        // Recover Pass
        'recoverPassText': 'Preencha os campos abaixo para definir a sua nova senha.'
    }
}